<template>
    <pz-simulators-template>
      <el-form
        slot="simulador"
        :ref="reference"
        status-icon
        :model="form"
        :rules="rules_formulario1"
        label-position="top"
        style="margin-top: 3%"
      >
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="valor_vehiculo"
            :error="errors.get('valor_vehiculo')"
            :label="$t('forms_flujo.SimulatorVehiculo.valor_vehiculo')"
          >
              <money
                  ref="valor_vehiculo"
                  v-model="form.valor_vehiculo"
                  v-money="money"
                  class="el-input__inner"
                  :placeholder="$t('forms_flujo.SimulatorVehiculo.valor_vehiculoGs')"
                 @blur.native="$nextTick(emitChange)"
                  ></money>
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="entrega_inicial"
            :error="errors.get('entrega_inicial')"
            :label="$t('forms_flujo.SimulatorVehiculo.entrega_inicial')"
          >
              <money
                  ref="entrega_inicial"
                  v-model="form.entrega_inicial"
                  v-money="money"
                  class="el-input__inner"
                  :placeholder="$t('forms_flujo.SimulatorVehiculo.entrega_inicialGs')"
                  @blur.native="$nextTick(emitChange)"
              ></money>
          </el-form-item>
        </el-col>       
      </el-row>
      <el-row :gutter="20">
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="valor_prestamo"
            :error="errors.get('valor_prestamo')"
            :label="$t('forms_flujo.SimulatorVehiculo.valor_prestamo')"
          >
            <el-input
                ref="valor_prestamo"
                v-model.number="valor_prestamo"
                :type="'text'"
                :placeholder="$t('forms_flujo.SimulatorVehiculo.valor_prestamo')"
            />
          </el-form-item>
        </el-col>
        <el-col
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="plazo"
            :error="errors.get('plazo')"
            :label="$t('forms_flujo.SimulatorVehiculo.plazo')"
          >
          <el-input-number
              v-model="form.plazo"
              :step="producto.step_cuota"
              :min="producto.min_cuota"
              :max="producto.max_cuota"
              @change="$nextTick(emitChange)"
              @input="handleInput"
              step-strictly
            />
          </el-form-item>
        </el-col>      
      </el-row>
        <comment :is="'pz-oferta-'+elOfertaView" />
      </el-form>
    </pz-simulators-template>
  </template>
  <script>
      import baseSimulator from "./baseSimulator";
      import PzSimulators from "@/components/Flujo/Templates/PzSimulatorsTemplate";
      import PzSimulatorsTemplate from "@/components/Flujo/Templates/PzSimulatorsTemplate";
      import currency from "@/commons/filters/other/currency";
      import {Money} from 'v-money'

      export default {
          name: 'PzVehiculoSimulator',
          components: {PzSimulatorsTemplate, PzSimulators, Money },
          mixins: [baseSimulator],
          computed: {
              valor_prestamo: function () {
                  let vehiculo = this.form.valor_vehiculo?this.form.valor_vehiculo:0;
                  let entrega_inicial = this.form.entrega_inicial?this.form.entrega_inicial:0;
                  var valor = parseFloat(vehiculo) - parseFloat(entrega_inicial);
                  this.form.monto = valor;
                  return this.formatTooltip(valor);

              }
        },
          data() {
              return {
                  vehiculo: true,
                  money: {
                      decimal: '.',
                      thousands: ',',
                      prefix: '',
                      suffix: '',
                      precision: 0,
                      masked: false
                  },
              }
          },
          created() {
              this.form.monto= 0;
              this.form.linea_otorgar_porc= 0;
              this.emitChange();
                },
          methods: {
              formatTooltip(val) {
                  if (val != null)
                      return currency(val,'',0);
              },

          }         
          
      }
  </script>
  