<template>
  <!-- SIMULADOR SLIDER-->
  <pz-simulators-template>
    <el-form
      slot="simulador"
      :ref="reference"
      status-icon
      :model="form"
      :rules="validation"
      class="slider_simulator sliderexterno"
    >
      <el-form-item

        prop="monto"
        :error="errors.get('monto')"
        style="margin-bottom: 0 !important;"
      >
        <span class="pz-font-size-md">
          {{ monto_label }}
        </span>
        <el-slider
          v-model="form.monto"
          class="sliderexterno"
          :show-input-controls="false"
          :tooltip-class="'colorToltip'"
          :format-tooltip="formatTooltip"
          :min="min_monto"
          :max="max_monto"
          :step="producto.step_monto"
          @change="$nextTick(emitChange)"
          @input="handleInput"
        />
        <div>
          <el-col
            :span="12"
            class="pz-font-size-bs"
          >
            {{ min_monto|currency(producto.moneda_solicitud.symbol, 0,{spaceBetweenAmountAndSymbol:
              true }) }}
          </el-col>
          <el-col
            :span="12"
            class="pz-font-size-bs text-right"
          >
            {{ max_monto|currency(producto.moneda_solicitud.symbol, 0,{spaceBetweenAmountAndSymbol:
              true }) }}
          </el-col>
        </div>
      </el-form-item>
      <el-form-item
        v-if="parseInt(min_cuota)!==parseInt(max_cuota)"
        prop="plazo"
        :error="errors.get('plazo')"
      >
        <span class="pz-font-size-md">{{ $t('forms_flujo.PzSimulator.how_many_fee') }}</span>
        <el-slider
          v-model="form.plazo"
          class="sliderexterno"
          :min="min_cuota"
          :max="max_cuota"
          :step="producto.step_cuota"
          :show-input-controls="false"
          @change="$nextTick(emitChange)"
          @input="handleInput"
        />
        <el-row :gutter="10">
          <el-col
            :span="12"
            class="pz-font-size-bs"
          >
            {{ min_cuota }}
          </el-col>
          <el-col
            :span="12"
            class="pz-font-size-bs text-right"
          >
            {{ max_cuota }}
          </el-col>
        </el-row>
      </el-form-item>
      <el-row :gutter="20">
        <el-col
          v-if="producto.needsSelds"
          :xl="{span: 12}"
          :lg="{span: 12}"
          :md="{span: 12}"
          :sm="{span: 24}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="trabajadores"
            :error="errors.get('trabajadores')"
          >
            <span>
              {{ $t('forms_flujo.PzSimulator.annual_sells') }}
            </span>
            <el-select
              v-model="form.trabajadores"
              placeholder="Ventas anuales"
              @change="$nextTick(emitChange)"
              @input="handleInput"
            >
              <el-option
                v-for="worker in workers"
                :key="worker.id"
                :label=" worker.workwers_range"
                :value="worker.id"
                :value-key="worker.id"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col
          v-if="producto.needsFirstDate"
          :xl="{span: 6}"
          :lg="{span: 6}"
          :md="{span: 6}"
          :sm="{span: 12}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="fecha_vencimiento"
            :error="errors.get('fecha_vencimiento')"
            class="bordered"
          >
            <span>
              {{ fechapago_label }}
            </span>
            <el-date-picker
              v-model="form.fecha_vencimiento"
              type="date"
              :span="2"
              :placeholder="$t('forms_flujo.PzSimulator.select_date')"
              :picker-options="pickerOptions"
              format="dd-MM-yyyy"
              value-format="dd-MM-yyyy"
              @change="$nextTick(emitChange)"
              @input="$nextTick(handleInput)"
            />
          </el-form-item>
        </el-col>
        <el-col
          v-if="producto.needsFirstDate"
          :xl="{span: 6}"
          :lg="{span: 6}"
          :md="{span: 6}"
          :sm="{span: 12}"
          :xs="{span: 24}"
        >
          <el-form-item
            prop="emision"
            :error="errors.get('emision')"
          >
            <span>
              {{ $t('forms_flujo.PzSimulator.emission_date') }}
            </span>
            <el-date-picker
              v-model="form.emision"
              type="date"
              :span="2"
              :placeholder="$t('forms_flujo.PzSimulator.select_date')"
              format="dd-MM-yyyy"
              :picker-options="pickerOptionsEmision"
              value-format="dd-MM-yyyy"
              @change="$nextTick(emitChange)"
              @input="$nextTick(handleInput)"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <comment :is="'pz-oferta-'+elOfertaView" />
    </el-form>
  </pz-simulators-template>
</template>
<script>
    import baseSimulator from "./baseSimulator";
    import PzSimulators from "@/components/Flujo/Templates/PzSimulatorsTemplate";
    import PzSimulatorsTemplate from "@/components/Flujo/Templates/PzSimulatorsTemplate";
    import currency from "@/commons/filters/other/currency";

    export default {
        name: 'PzSliderSimulator',
        components: {PzSimulatorsTemplate, PzSimulators},
        mixins: [baseSimulator],

        methods: {
            formatTooltip(val) {
                if (val != null)
                    return this.producto.moneda_solicitud.symbol + currency(val,'',0);
            }
        }
    }
</script>
